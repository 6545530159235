import { translate } from '@avensia/scope';
import { styled } from '@glitz/react';
import React, { useState, useRef } from 'react';
import { sourceSansPro, FontWeight } from 'Shared/Style';
import { useClickOutside } from 'Shared/use-click-outside';
import MadeToMeasureToggle from './MadeToMeasureToggle';
export default function ({ itemProps, isB2B }) {
    const { sku, pharmaCode, model, compression, color, typeOfBand, size, mtmMeasurements, hasSeriesSize, unit, leftRight, length, footType, tubeType, crotchType, gender, migel, itemLanguage, height, } = itemProps;
    const [toggleMtm, setToggleMtm] = useState(false);
    const elementRef = useRef();
    useClickOutside(elementRef, () => {
        if (toggleMtm)
            setToggleMtm(!toggleMtm);
    });
    const b2clist = () => {
        return (React.createElement(React.Fragment, null,
            sku && React.createElement(Cell, null, `${translate('/Facets/sku')}: ${sku}`),
            pharmaCode && isB2B && React.createElement(Cell, null, `${translate('/Facets/pharmaCode')}: ${pharmaCode}`),
            migel && React.createElement(Cell, null, `${translate('/Cart/Additional/Migel')}: ${migel}`),
            model && React.createElement(Cell, null, `${translate('/Facets/model')}: ${model}`),
            compression && React.createElement(Cell, null, `${translate('/Facets/CCL')} : ${compression}`),
            (mtmMeasurements === null || mtmMeasurements === void 0 ? void 0 : mtmMeasurements.length) > 0 ? (React.createElement(MadeToMeasureToggle, { isOpen: toggleMtm, onToggle: () => setToggleMtm(!toggleMtm), mtmMeasurements: mtmMeasurements, elementRef: elementRef })) : (React.createElement(Cell, null, `${translate('/Facets/size')} : ${size}`)),
            color && React.createElement(Cell, null, `${translate('/Facets/color')}: ${color}`),
            typeOfBand && React.createElement(Cell, null, `${translate('/Facets/typeOfBand')}: ${typeOfBand}`),
            footType && React.createElement(Cell, null, `${translate('/Facets/footType')}: ${footType}`),
            length && React.createElement(Cell, null, `${translate('/Facets/length')}: ${length}`),
            tubeType && React.createElement(Cell, null, `${translate('/Facets/tubeType')}: ${tubeType}`),
            crotchType && React.createElement(Cell, null, `${translate('/Facets/crotchType')}: ${crotchType}`),
            gender && React.createElement(Cell, null, `${translate('/Facets/gender')}: ${gender}`),
            height && React.createElement(Cell, null, `${translate('/Facets/height')}: ${height}`),
            leftRight && React.createElement(Cell, null, `${translate('/Cart/Additional/Unit')}: ${leftRight}`),
            unit && React.createElement(Cell, null, `${translate('/Cart/Additional/Unit')}: ${unit}`),
            hasSeriesSize && React.createElement(HighlightedCell, null, translate('/Checkout/MadeToMeasure/SeriesSizing'))));
    };
    const b2blist = () => {
        return (React.createElement(React.Fragment, null,
            sku && React.createElement(Cell, null, `${translate('/Facets/sku')}: ${sku}`),
            pharmaCode && isB2B && React.createElement(Cell, null, `${translate('/Facets/pharmaCode')}: ${pharmaCode}`),
            migel && React.createElement(Cell, null, `${translate('/Cart/Additional/Migel')}: ${migel}`),
            model && React.createElement(Cell, null, `${translate('/Facets/model')}: ${model}`),
            compression && React.createElement(Cell, null, `${translate('/Facets/CCL')} : ${compression}`),
            (mtmMeasurements === null || mtmMeasurements === void 0 ? void 0 : mtmMeasurements.length) > 0 ? (React.createElement(MadeToMeasureToggle, { isOpen: toggleMtm, onToggle: () => setToggleMtm(!toggleMtm), mtmMeasurements: mtmMeasurements, elementRef: elementRef })) : (React.createElement(Cell, null, `${translate('/Facets/size')} : ${size}`)),
            length && React.createElement(Cell, null, `${translate('/Facets/length')}: ${length}`),
            typeOfBand && React.createElement(Cell, null, `${translate('/Facets/typeOfBand')}: ${typeOfBand}`),
            tubeType && React.createElement(Cell, null, `${translate('/Facets/tubeType')}: ${tubeType}`),
            footType && React.createElement(Cell, null, `${translate('/Facets/footType')}: ${footType}`),
            color && React.createElement(Cell, null, `${translate('/Facets/color')}: ${color}`),
            gender && React.createElement(Cell, null, `${translate('/Facets/gender')}: ${gender}`),
            height && React.createElement(Cell, null, `${translate('/Facets/height')}: ${height}`),
            unit && React.createElement(Cell, null, `${translate('/Cart/Additional/Unit')}: ${unit}`),
            leftRight && React.createElement(Cell, null, `${translate('/Cart/Additional/Unit')}: ${leftRight}`),
            itemLanguage && React.createElement(Cell, null, `${translate('/Cart/Additional/ItemLanguage')}: ${itemLanguage}`),
            crotchType && React.createElement(Cell, null, `${translate('/Facets/crotchType')}: ${crotchType}`),
            hasSeriesSize && React.createElement(HighlightedCell, null, translate('/Checkout/MadeToMeasure/SeriesSizing'))));
    };
    return (
    //Data from BE is not sorted so we need to render each component according to Figma design
    React.createElement(Additional, null, isB2B ? b2blist() : b2clist()));
}
const Additional = styled.div(sourceSansPro(12), {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
});
const Cell = styled.span({});
export const HighlightedCell = styled(Cell, { fontWeight: FontWeight.SemiBold });
export const Title = styled.div({
    margin: {
        xy: 0,
    },
    fontSize: 12,
});
